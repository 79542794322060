var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c(
          "el-table",
          {
            staticClass: "page-table",
            attrs: { border: "", data: _vm.flowListData },
          },
          [
            _vm._l(_vm.flowListColumns, function (item, index) {
              return _c("el-table-column", {
                key: index,
                attrs: { prop: item.key, label: item.title, align: "center" },
                scopedSlots: _vm._u(
                  [
                    item.key == "link.name"
                      ? {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.$t(scope.row.link.name)) + " "
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              })
            }),
            _c("el-table-column", {
              attrs: { label: "更新时间", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.DateUtil.format(
                              new Date(scope.row.updateDate),
                              "yyyy-MM-dd hh:mm:ss"
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: _vm.$t("lang_describe"), align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$t(scope.row.link.description)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("lang_state"),
                prop: "link.status",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.link.status == 0
                        ? _c("el-tag", { attrs: { type: "primary" } }, [
                            _vm._v(_vm._s(_vm.$t("lang_edit_z"))),
                          ])
                        : _vm._e(),
                      scope.row.link.status == 1
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v(_vm._s(_vm.$t("lang_published"))),
                          ])
                        : _vm._e(),
                      scope.row.link.status == 2
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v(_vm._s(_vm.$t("lang_closed"))),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: _vm.$t("lang_operation"), align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#007aff" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.design(scope.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_see")))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
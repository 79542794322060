










































import { Component, Vue } from 'vue-property-decorator';
import { getHistoryFlows } from '@/api/flow-design/FlowApi';
import DateUtil from 'global-ui/packages/utils/DateUtil';
interface FlowListColumns {
  title: string;
  key?: string;
  slot?: string;
  align?: string;
}

@Component({
  name: 'FlowHistory'
})
export default class FlowHistory extends Vue {
  DateUtil: any = DateUtil;
  // 表单搜索Model
  flowListColumns: FlowListColumns[] = [
    {
      title: this.$t('lang_name') as string,
      key: 'link.displayName'
    },
    {
      title: this.$t('lang_code') as string,
      key: 'link.code'
    },

    {
      title: this.$t('lang_edition') as string,
      key: 'link.version'
    }
  ];
  flowListData: Record<string, any>[] = [];
  lid: any = null;

  created() {
    this.lid = this.$route.query.lid;
    this.getHistoryFlow();
  }

  // 获取表单列表
  getHistoryFlow(): void {
    getHistoryFlows(this.lid).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.flowListData = res.data;
      }
    });
  }

  // 数据实例
  design(row) {
    this.$router.push({ path: '/flow/design', query: { id: row.link.id } });
  }

  /**
   * 返回应用管理列表
   */
  back() {
    this.$router.go(-1);
  }
}
